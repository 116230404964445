<template>
  <div id="print">
    <div class="header">
      <h1>运营大数据报告（第{{ reportData.period }}期）</h1>
      <p>数据来源：巨立云电梯物联网平台</p>
      <p>客户名称： {{ reportData.customer }}</p>
    </div>
    <main>
      <div class="box">
        <h5><img src="../assets/report/quyu.png" />platform</h5>
        <div v-if="reportData">
          <label><span>报告时间</span></label>
          {{ formatDate(reportData.dateTime) }}
        </div>
        <div>
          <label><span>报告周期</span></label>
          {{ formatDate(reportData.dateFrom) }}-
          {{ formatDate(reportData.dateTo) }}
        </div>
        <div>
          <label><span>总入网台量</span></label>
          <span class="stick">{{ reportData.totalElevators }}</span>
          台
        </div>
        <div>
          <label><span>智能硬件占比</span></label>
          <span class="stick">{{ reportData.aiRatio }}</span>
          %
        </div>
        <div>
          <label><span>本周期累计健康运行时长&次数</span></label>
          <span class="stick">{{ reportData.runDuration }}</span>
          小时 &
          <span class="stick">{{ reportData.runTimes }}</span>
          次
        </div>
      </div>
      <div class="box">
        <h5><img src="../assets/report/yunying.png" />运营数据</h5>
        <div>
          <label><span>新增入网台量</span></label>
          <div class="width">
            <span class="stick">{{ reportData.newElevators }}</span>
            台
          </div>
          <label><span>电瓶车检测报警次数</span></label>
          <div class="width">
            <span class="stick">{{ reportData.electromobileWarning }}</span>
            次
          </div>
        </div>
        <div>
          <label><span>发生故障总次数</span></label>
          <div class="width">
            <span class="stick">{{ reportData.faultTimes }}</span>
            次
          </div>
          <label><span>发生故障电梯总台量</span></label>
          <div class="width">
            <span class="stick">{{ reportData.faultElevators }}</span>
            台
          </div>
        </div>
        <div>
          <label><span>困人次数</span></label>
          <div class="width">
            <span class="stick">{{ reportData.trapTimes }}</span>
            次
          </div>
          <label><span>最多的故障原因</span></label>
          <div class="width">{{ reportData.maxFaultReason }}</div>
        </div>
        <div>
          <label style="width: 320px">
            <span>因故障导致停梯时长最久的电梯</span>
          </label>
          {{ reportData.faultStopElevator }}
        </div>
        <ul>
          <li>
            <label>使用频率最高的电梯TOP5</label>
            <p
              v-for="(item, index) in reportData.mostFrequentlyUsedTop5"
              :key="index"
            >
              <span>{{ index + 1 }}</span>
              {{ item }}
            </p>
          </li>
          <li>
            <label>故障次数最多的电梯TOP5</label>
            <p
              v-for="(item, index) in reportData.faultTimesElevatorTop5"
              :key="index"
            >
              <span>{{ index + 1 }}</span>
              {{ item }}
            </p>
          </li>
        </ul>
        <div style="clear: both"></div>
      </div>
      <div class="box">
        <h5><img src="../assets/report/weibao.png" />维保数据</h5>
        <div>
          <label><span>维保工单总数</span></label>
          <div class="width">
            <span class="stick">
              {{ reportData.totalMaintenanceBills }}
            </span>
            单
          </div>
          <label><span>维保工单完成数</span></label>
          <div class="width">
            <span class="stick">
              {{ reportData.finishedMaintenanceBills }}
            </span>
            单
          </div>
        </div>
        <div>
          <label><span>维保总耗时</span></label>
          <div class="width">
            <span class="stick">
              {{ formatSecond(reportData.maintenanceTotalTime, "h") }}
            </span>
            小时
          </div>
          <label><span>保养作业平均耗时</span></label>
          <div class="width">
            <span class="stick">
              {{ formatSecond(reportData.maintenanceAverageTime) }}
            </span>
            分钟
          </div>
        </div>
        <div>
          <label><span>保养超期次数</span></label>
          <div class="width">
            <span class="stick">
              {{ reportData.maintenanceExpiredTimes }}
            </span>
            次
          </div>
          <label><span>保养不合格单数</span></label>
          <div class="width">
            <span class="stick">
              {{ reportData.unqualifiedMaintenanceTimes }}
            </span>
            单
          </div>
        </div>
        <div>
          <label><span>救援作业平均耗时</span></label>
          <div class="width">
            <span class="stick">
              {{ formatSecond(reportData.trapBillAverageTime) }}
            </span>
            分钟
          </div>
          <label><span>年检异常电梯台数</span></label>
          <div class="width">
            <span class="stick">
              {{ reportData.annualInspectionExceptionCount }}
            </span>
            台
          </div>
        </div>
      </div>
      <div id="logo">巨立云<span>Joycloud</span></div>
    </main>
    <div class="footer"><label>技术支持：</label>苏州钜立智能系统有限公司</div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "Report",
  setup(props, { emit }) {
    emit("public_header", false);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      reportData: "",
    });

    const init = async () => {
      let { data } = await proxy.$api.common.getReport(route.query.id);
      data.dateTime = data.dateTime.split(" ")[0].split("-");
      data.faultTimesElevatorTop5 = data.faultTimesElevatorTop5.split(",");
      data.mostFrequentlyUsedTop5 = data.mostFrequentlyUsedTop5.split(",");
      data.dateFrom = data.dateFrom.split("-");
      state.reportData = data;
    };

    const formatDate = (value) => {
      // 日期
      var date = new Date(value);
      var y = date.getFullYear(); //获取年
      var m = date.getMonth() + 1; //获取月
      m = m < 10 ? "0" + m : m; //判断月是否大于10
      var d = date.getDate(); //获取日
      d = d < 10 ? "0" + d : d; //判断日期是否大10
      return y + "年" + m + "月" + d + "日"; //返回时间格式
    };

    const formatSecond = (second, fmt) => {
      const data =
        fmt === "h" ? Math.floor(second / 3600) : Math.floor(second / 60);
      return data;
    };

    init();

    return {
      ...toRefs(state),
      formatDate,
      formatSecond,
    };
  },
};
</script>

<style lang="scss">
#print {
  width: 794px;
  height: 1128px;
  overflow: hidden;
  background: #fff url("../assets/report/bg.png") no-repeat 96% 15%;
  background-size: 300px 201px;
  color: #333;
  font-size: 14px;
  letter-spacing: 2px;
  .header {
    padding: 10px 30px;
    background: #5a2bfd;
    color: #fff;
    h1 {
      font-size: 32px;
      letter-spacing: 5px;
      margin-bottom: 10px;
      text-shadow: 5px 5px 5px #a762f0;
    }
    p {
      margin: 5px 0;
      color: #ccc;
    }
  }
  main {
    position: relative;
    #logo {
      position: absolute;
      /* right: 15px;
      top: 80px; */
      right: 10px;
      top: 80px;
      color: #c398fe;
      text-shadow: 5px 5px 5px #7472db;
      font-weight: bold;
      transform: rotate(-32deg) scale(0.7, 0.7);
      font-size: 12px;
      letter-spacing: -1px;
      span {
        letter-spacing: 0;
      }
    }
    div.box {
      padding: 0 30px;
      margin-bottom: 19px;
      text-align: center;
      > h5 {
        font-size: 20px;
        padding: 10px 0;
        margin: 16px 0;
        color: #703bfa;
        text-align: left;
        img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      > div {
        text-align: left;
        font-size: 17px;
        line-height: 30px;
        label {
          display: inline-block;
          margin-right: 15px;
          font-weight: bold;
          color: #606266;
          > span {
            padding: 2px 2px 2px 5px;
            border-radius: 3px;
            color: #000;
            position: relative;
          }
        }
        span.stick {
          font-size: 18px;
          font-weight: bold;
          display: inline-block;
          margin-right: 5px;
          color: #f59b4b;
        }
        div.width {
          display: inline-block;
        }
      }
      ul {
        display: flex;
        li {
          position: relative;
          list-style: none;
          display: inline-block;
          text-align: left;
          font-size: 17px;
          margin: 20px 5px 0 0;
          padding: 5px 8px;
          border-radius: 5px;
          background: #9657f3;
          label {
            color: #fff;
            font-weight: bold;
            margin-bottom: 10px;
            display: block;
          }
          p {
            color: #fff;
            position: relative;
            padding-left: 20px;
            margin: 5px 0;
            line-height: 26px;
            span {
              color: #fff;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }
      &:nth-child(1) > div label {
        margin-right: 20px;
      }
      &:nth-child(2) > div {
        label {
          width: 225px;
        }
        div.width {
          width: 120px;
        }
      }
      &:nth-child(3) > div {
        label {
          width: 185px;
        }
        div.width {
          width: 150px;
        }
      }
    }
  }
  .footer {
    width: 100%;
    padding: 8px 30px;
    box-sizing: border-box;
    text-align: center;
    color: #ccc;
    background: #5a2bfd;
  }
}
</style>
